<template>
  <div class="" v-if="list">
    <board-filter @onSearch="onSearch"/>
    <div class="strTablescr">
     <div class="strTablePC">
        <table class="strTablePT">
          <colgroup>
            <col width="10%">
            <col width="10%">
            <col width="10%">
            <col width="40%">
            <col width="20%">
            <col width="10%">
          </colgroup>
          <thead>
            <tr>
              <th>{{$t('front.board.check')}}</th>
              <th>{{$t('front.board.status')}}</th>
              <th>{{$t('front.board.type')}}</th>
              <th>{{$t('front.board.title')}}</th>
              <th>{{$t('front.board.regDate')}}</th>
              <th>{{$t('front.board.delete')}}</th>
            </tr>
          </thead>
          <tbody>
            <template v-if="list.length > 0">
              <template v-for="item in list" :key="item.boardIdx">
                <tr>
                  <td><span class="ocmsg" :class="item.isReadAdmin === 'Y' ? 'openmsg': 'closemsg'"></span></td>
                  <td>
                     <span v-if="item.status === 'ANSWER'" class="nbicon bkblbg">{{$t('front.qnaStatus.answer')}}</span>
                     <span v-if="item.status === 'WAIT'" class="nbicon bkblbg">{{$t('front.qnaStatus.wait')}}</span>
                  </td>
                  <td>{{$t('front.qnaTypeCategory.' + item.faqType)}}</td>
                  <td @click.prevent="onClickRead(item.boardIdx)">
                     <a href="#" >
                        {{item.title}}
                        <span class="newicon rdbg ml5">N</span>
                     </a>
                  </td>
                  <td>{{dateFormatForBoard(item.regDate)}}</td>
                  <td><span class="nbicon bgrbg" @click.prevent="onClickRemove(item.boardIdx)">{{$t('front.board.delete')}}</span></td>
                </tr>
              </template>
            </template>
            <template v-else>
              <tr>
                 {{$t('front.board.noAsk')}}
              </tr>
            </template>
          </tbody>
        </table>
     </div>
     <div class="strTableM">
        <div class="strTablePTM">
           <template v-if="list.length > 0">
             <template v-for="item in list" :key="item.boardIdx">
               <ul>
                 <li>
                    <em>{{$t('front.board.check')}}</em>
                    <span class="ocmsg" :class="item.isReadAdmin === 'Y' ? 'openmsg': 'closemsg'"></span>
                 </li>
                 <li>
                    <em>{{$t('front.board.status')}}</em>
                    <span v-if="item.status === 'ANSWER'" class="blc nbicon wtblbg">{{$t('front.qnaStatus.answer')}}</span>
                    <span v-if="item.status === 'WAIT'" class="nbicon bkblbg">{{$t('front.qnaStatus.wait')}}</span>
                 </li>
                 <li>
                    <em>{{$t('front.board.type')}}</em>
                    {{$t('front.qnaTypeCategory.' + item.faqType)}}
                 </li>
                 <li @click.prevent="onClickRead(item.boardIdx)">
                    <em>{{$t('front.board.title')}}</em>
                    <a href="#" >
                      {{item.title}}
                      <span class="newicon rdbg ml5">N</span>
                    </a>
                 </li>
                 <li>
                    <em>{{$t('front.board.regDate')}}</em>
                    {{dateFormatForBoard(item.regDate)}}
                 </li>
                 <li>
                    <em>{{$t('front.board.delete')}}</em>
                    <span class="nbicon bgrbg" @click.prevent="onClickRemove(item.boardIdx)">{{$t('front.board.delete')}}</span>
                 </li>
               </ul>
             </template>
           </template>
           <template v-else>
             <ul>
                <li>{{$t('front.common.notFoundList')}}</li>
             </ul>
           </template>
        </div>
     </div>
  </div>

    <div class="applibtns">
      <a @click="getAcc">{{$t('front.board.bankAccountQna')}}</a>
      <a @click="onClickWrite">{{$t('front.board.qnaWrite')}}</a>
    </div>

    <pagination :pageNum="pageInfo.page"
                :pageSize="pageInfo.count_per_list"
                :totalCount="pageInfo.tatal_list_count"
                @goToPage="loadList()"/>

  </div>

</template>

<script>
import Pagination from '@/components/ui/Pagination'
import BoardFilter from '@/components/common/BoardFilter'
import { mapState } from 'vuex'

export default {
  name: 'qna',
  components: {
    BoardFilter,
    Pagination
  },
  data () {
    return {
      list: null,
      boardType: 'faq',
      searchParam: {
        searchOption: '',
        searchValue: ''
      },
      idxList: [],
      category: ''
    }
  },
  async created () {
    await this.loadList()
  },
  computed: {
    ...mapState([
      'userData'
    ])
  },
  methods: {
    async getAcc () {
      const title = '계좌문의입니다.'
      const content = '계좌문의입니다.'
      const params = {
        title: title,
        content: content,
        boardType: 'faq',
        category: '',
        faqType: 'bankaccount'
      }

      await this.setSaveAccountBoard(params, async function () {
        await this.loadList()
      }.bind(this))
    },
    onCategoryChange (category) {
      this.category = category
      this.loadList()
    },
    onSearch (value) {
      this.searchParam.searchOption = value.type
      this.searchParam.searchValue = value.text

      this.loadList()
    },
    async loadList () {
      const params = {
        boardType: this.boardType,
        category: this.category,
        memId: this.userData.memId
      }

      const searchValue = this.searchParam.searchValue
      if (searchValue) {
        params.searchOption = this.searchParam.searchOption
        params.searchValue = this.searchParam.searchValue
      }

      const result = await this.getBoardList(params, this.pageInfo.page)
      console.log(result)
      result.list.forEach(item => {
        item.isContentOpen = false
      })
      this.list = result.list
      this.idxList = result.idxList
    },
    onClickRead (id) {
      if (!id) id = 1
      this.$router.push({ name: 'qnaread', params: { boardIdx: id } })
    },
    onClickWrite () {
      this.$router.push({ name: 'qnaWrite' })
    },
    onClickRemove (boardIdx) {
      if (boardIdx) {
        const params = {
          boardIdx: boardIdx,
          boardType: this.boardType,
          delYn: 'Y'
        }

        this.setSaveBoard(params, 'delete', () => {
          this.loadList()
        })
      }
    }
  }
}
</script>
<style scoped>
a{color:#121212;}
</style>
<style src="@/styles/striNew.css"></style>
<style scoped>
.applibtns{gap:20px;}
@media (max-width: 1000px) {
  .strTablePTM li {width: 100%;}
}
</style>
